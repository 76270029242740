.resources-table-item-input-group {
  display: flex;
  justify-content: flex-end;
}

.resources-table-item-input {
  display: flex;
  justify-content: flex-end;
}

.resources-table-item-input__val {
  display: block;
  padding: 8px 16px;
  width: 100%;
  background: #FFFFFF;
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #DBDFE5;
  box-sizing: border-box;
  border-radius: 4px;

  &::placeholder {
    color: #7E8FA5;
  }
  &:focus {
    color: #373F51;
    border-color: #17AFFF;
  }
}

.resources-table-item-input__val--time {
  flex: 1;
  padding-right: 3px;
  padding-left: 3px;
  text-align: center;
}

.resources-table-item-input__val--rate {
  width: 64px;
  padding-right: 7px;
  padding-left: 7px;
  text-align: center;
}

.resources-table-item-input__val--error {
  border-color: #FF4743;
  &:hover {
    border-color: #FF4743;
  }
  &:focus {
    border-color: #FF4743;
  }
}

.resources-table-item-input-sep {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
}

.resources-table-item-input-sep--disable-padding {
  padding-right: 0;
}