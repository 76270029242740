.live-feed-item-preview__ico {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: #373F51;
}


.live-feed-item-preview__status {
  margin: 0;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: #373F51;
  font-weight: 600;
}

.live-feed-item-preview__ico--multiple {
  background-color: #373F51;
  color: #FDFDFD;
  font-size: 0.625rem;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}
