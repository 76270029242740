.popup-shift-rule-date-list {

}

.popup-shift-rule-date-list-item {
  position: relative;
  padding: 14px 18px 14px 40px;
  border-bottom: 1px solid #E7E9ED;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #F5F6F8;
  }

  &.mod-selected {
    background-color: #EDF9FF;

    .popup-shift-rule-date-list-item-checkbox-ico {
      display: block;
    }
  }

  &.mod-disabled {
    cursor: default;

    &:hover {
      background-color: #FFF;
    }

    .popup-shift-rule-date-list-item-text {
      opacity: 0.4;
    }
  }
}

.popup-shift-rule-date-list-item-text {
  font-size: 15px;
  color: #4B5262;
}

.popup-shift-rule-date-list-item-checkbox-ico {
  display: none;
  position: absolute;
  top: 50%;
  left: 18px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);

  .svg-ico {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 11px;
    fill: #00A6FF;
    transform: translateY(-50%) translateX(-50%);
  }
}