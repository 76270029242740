.input-wrapper-icon {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 38px;
  fill: #7E8FA5;
  height: calc(100% - 2px);
  background: #FBFBFB;
  border-right: 1px solid #DBDFE5;
  border-radius: 3px 0 0 3px;
}

.input-wrapper-icon__val {
  position: absolute;
  top: 50%;
  left: 50%;
  fill: inherit;
  transform: translateX(-50%) translateY(-50%);
}

.input-wrapper-icon__val--pound {
  width: 10px;
  height: 12px;
}

.input-wrapper-icon__val--calendar {
  width: 18px;
  height: 18px;
}