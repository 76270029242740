.payrate-clear {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.payrate-clear__text {
  min-width: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.payrate-clear__button {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding: 0;
  fill: #BDC6D1;
  &:hover {
    fill: #00A8FF;
    .payrate-clear__hint {
      display: block;
      z-index: 99;
    }
  }
}

.payrate-clear__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: inherit;
}

.payrate-clear__hint {
  position: absolute;
  display: none;
  top: 100%;
  left: 50%;
  width: 181px;
  margin-top: 10px;
  padding: 4px  8px;
  background: #373F51;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  transform: translateX(-50%);
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
}