.clients {
  display: flex;
  background: #FFFFFF;
  border: 1px solid #E7E9ED;
  box-sizing: border-box;
  border-radius: 3px;
}

.clients__item {
  padding: 21px 32px;
  box-sizing: border-box;
}

.clients__item--list {
  width: 527px;
  background: #fff;
  border-right: 1px solid #E7E9ED;
  border-radius: 3px 0 0 3px;
}

.clients__item--services {
  flex: 1;
  min-width: 0;
  background: rgba(219, 223, 229, 0.2);
  border-radius: 0 3px 3px 0;
}
