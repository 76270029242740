.text-tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  &:hover {
    .text-tooltip__dropdown {
      display: block;
    }
    .text-tooltip__ico {
      fill: #373F51;
    }
  }
}

.text-tooltip--dark {
  margin: 0 12px;
  .text-tooltip__dropdown {
    left: 50%;
    top: 100%;
    width: 214px;
    bottom: auto;
    margin-top: 10px;
    margin-left: -107px;
    background: #373F51;
    &:after {
      top: auto;
      left: 50%;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: -12px;
      margin-left: -7px;
    }
  }

  .text-tooltip__value {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 15px;
    background: #373F51;
    font-weight: 600;
  }
}

.text-tooltip--right {
  margin: 0 0 0 6px;
  .text-tooltip__dropdown {
    top: 50%;
    left: 100%;
    width: auto;
    white-space: nowrap;
    margin-left: 10px;
    margin-top: 0;
    transform: translateY(-50%);
    &:after {
      top: 50%;
      left: 0;
      bottom: auto;
      margin-top: -8px;
      margin-bottom: 0;
      margin-left: -3px;
    }
  }
}


.text-tooltip__ico {
  display: block;
  width: 12px;
  height: 12px;
  cursor: pointer;
  fill: #BDC6D1;
}

.text-tooltip__dropdown {
  position: absolute;
  display: none;
  left: -17px;
  bottom: 100%;
  width: 175px;
  margin-bottom: 12px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);

  &:after {
    position: absolute;
    content: "";
    top: 100%;
    left: 15px;
    margin-top: -12px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    background: inherit;
    box-shadow: inherit;
  }

}

.text-tooltip__value {
  position: relative;
  z-index: 2;
  display: block;
  padding: 6px 9px;
  color: #7E8FA5;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 4px;
}
