.live-feed-item--expanded-start {
  border-color: #00A8FF;
  max-height: none;

  &:hover {
    border-color: #00A8FF;
  }

  .live-feed-item__box--preview {
    background: #F4FCFF;
    border-radius: 4px 4px 0 0;

    &:after {
      content: '';
    }
  }
}

.live-feed-item--expanded-end {
  .live-feed-item__wrapper {
    margin-top: 0;
  }
}

.live-feed-item--expanded {
  .live-feed-item__box--details {
    overflow: visible;
  }
}

.live-feed-item--urgent {
  .live-feed-item-preview__status {
    color: #FF4743;
  }

  .live-feed-item-preview__ico {
    fill: #FF4743;
  }

  .live-feed-item-preview__ico--multiple {
    background-color: #FF4743;
  }

  .live-feed-item-preview__title--counter {
    color: #FF4743;
  }
}

.live-feed-item--selected {
  .live-feed-item-preview__select {
    display: block;
  }
}

.live-feed-item--new {
  /*border: 1px solid #E7E9ED;*/

  .live-feed-item__box--preview {
    background: #F4FCFF;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 166, 255, 0.04), rgba(0, 166, 255, 0.04)), #F4FCFF;
    }
  }

  .live-feed-item-preview__label--new {
    display: block;
  }
}

.live-feed-item--new-animation {
  animation: feedItemMoveDown 1s cubic-bezier(0, 1, 0, 1) forwards, feedItemMoveIn 1s linear forwards;
}

.live-feed-item--remove {
  animation: feedItemMoveOut 300ms ease-in forwards, feedItemMoveUp 300ms cubic-bezier(0.00, -0.00, 0.00, 1.00) 250ms forwards;
}

.live-feed-item--disabled {
  pointer-events: none;
  border: 1px solid #E7E9ED;

  .live-feed-item__box--preview {
    background: #F8F9FA;
  }

  .live-feed-item-preview__label--disabled {
    display: block;
  }

  .live-feed-item-preview__lock {
    display: block;
  }

  .live-feed-item-preview__ico {
    fill: #A4A6AE;
  }

  .live-feed-item-preview__status {
    color: #A4A6AE;
  }

  .live-feed-item-preview__title {
    color: #A4A6AE;
  }

  .live-feed-item-preview__subtitle {
    color: #A4A6AE;
  }

  .live-feed-item-preview__actions {
    display: none;
  }
}
