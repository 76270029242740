.popup-invite-employee-message__wrapper {
  padding-top: 56px;
  padding-bottom: 44px;
  text-align: center;
}

.popup-invite-employee-message__data {
  display: inline-block;
  width: 100%;
  max-width: 450px;
}

.popup-invite-employee-message__icon {
  position: relative;
  margin: 0 auto 36px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #00BB28;
  box-sizing: border-box;
}

.popup-invite-employee-message__icon-val {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 26px;
  height: 18px;
  fill: #00BB28;
  transform: translateX(-50%) translateY(-50%);
}

.popup-invite-employee-message__title {
  margin: 0 0 36px;
  color: #373F51;
  font-size: 22px;
  line-height: 27px;
}

.popup-invite-employee-message__sep {
  display: block;
  margin: 0 auto 36px;
  width: 56px;
  height: 1px;
  background: #E7E9ED;
}

.popup-invite-employee-message__text {
  margin: 0 0 31px;
  color: #7E8FA5;
  font-size: 16px;
  line-height: 24px;
}

input.popup-invite-employee-message__input {
  padding-right: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  &:focus + .popup-invite-employee__button {
    border-color: #17AFFF;
  }
}

.btn.popup-invite-employee-message__button {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  line-height: 44px;
}