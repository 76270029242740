.list-filter-options {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  padding: 14px 20px 0;
  box-sizing: border-box;
}


.list-filter-option__category {}
.list-filter-option__category-title {
  display: block;
  margin-bottom: 16px;
  color: #7E8FA5;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
}

.list-filter-options__toggle-btn {
  position: relative;
  z-index: 3;
  padding-bottom: 14px;
  border-bottom: 1px solid #E7E9ED;
}

.list-filter-options__area {
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 0;
  flex: 1;

  &:after {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(219, 223, 229, 0.2);
  }
}

.list-filter-options__area--disabled {
  &:after {
    content: '';
  }

  .list-filter-options__header,
  .list-filter-options__data {
    opacity: .38;
  }
}

.list-filter-options__title {
  margin: 0 0 16px;
  font-size: 15px;
  line-height: 18px;
  color: #373F51;
  font-weight: 600;
}

.list-filter-options-search {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.list-filter-options-search__input {
  display: block;
  width: 100%;
  padding: 11px 16px 11px 40px;
  background: none;
  font-size: 14px;
  line-height: 17px;
  color: #333C48;
  background: #F8F8F8;
  border: 1px solid #DBDFE5;
  box-sizing: border-box;
  border-radius: 2px;

  &::placeholder {
    color: #979797;
  }
  &:focus {
    border-color: #00A8FF;
  }

}

.list-filter-options-search__ico {
  position: absolute;
  top: 50%;
  left: 11px;
  width: 17px;
  height: 19px;
  fill: #B8BAC1;
  transform: translateY(-50%);
}

.list-filter-options__data {
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: none;
    border-right: 0;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-right: 0;
    border-radius: 2px;
  }
}

.list-filter-options__actions {
  display: flex;
  margin: 11px -11px 19px;
}

.list-filter-options__actions-box {
  position: relative;
  padding: 0 11px;

  &:first-child {
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      width: 1px;
      height: 16px;
      margin-top: -8px;
      background: #E8EBF0;
    }
  }
}

.list-filter-options__action {
  display: block;
  padding: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 17px;

  &:hover {
    color: #00A8FF;
  }
}


.list-filter-option {
  position: relative;
  display: block;
  margin-bottom: 22px;
  padding-left: 24px;
  min-height: 17px;
  color: #373F51;
  font-size: 14px;
  line-height: 17px;
}

.list-filter-option__input {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked {
    & + .list-filter-option__data {
      .list-filter-option__ico {
        background: #00A8FF;
        border-color: #00A8FF;
      }
      .list-filter-option__ico-val {
        display: block;
      }
    }
  }
}

.list-filter-option__ico {
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  margin-top: -9px;
  background: #FFFFFF;
  border: 1px solid #B8BAC1;
  box-sizing: border-box;
  border-radius: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    content: '';
    width: 4px;
    height: 4px;
    margin: -2px 0 0 -2px;
    background: #fff;
    border-radius: 50%;
  }
}

.list-filter-option__ico-val {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 9px;
  fill: #fff;
  transform: translateY(-50%) translateX(-50%);
}

.list-filter-option__ico--checkbox {
  border-radius: 2px;
  &:after {
    display: none;
  }
}