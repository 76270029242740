.package-restriction {
  padding-bottom: 8px;
}
.package-restriction__box {}

.package-restriction__box--img {
  margin-bottom: 14px;
  position: relative;
  padding-bottom: 50%;
}

.package-restriction__box--info {
  margin-bottom: 43px;
}

.package-restriction__box--actions {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.package-restriction__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
}

.package-restriction__title {
  margin-bottom: 4px;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.33;
  color: #373F51;
}

.package-restriction__subtitle {
  font-size: 0.9375rem;
  text-align: center;
  color: #7E8FA5;
}

.package-restriction__button {
  margin-bottom: 24px;
  min-width: 200px;
}

.package-restriction__link {
  display: block;
  min-width: 200px;
  height: 36px;
  line-height: 36px;
  font-size: 0.875rem;
  text-align: center;
  color: #7E8FA5;
  transition: .3s ease;

  &:hover {
    color: #00A8FF;
  }

}

