.shift-rule-item {
  margin-bottom: 20px;
}

.shift-rule-wrapper-list {
  display: flex;
  .shift-rule-wrapper {
    display: block;
    &.mod-data {
      flex: 1;
    }
  }
}

.shift-rule-wrapper {
  display: flex;
  padding: 24px;
  &.mod-tabs {
    padding-bottom: 0;
  }

  &.mod-pb-10 {
    padding-bottom: 10px;
  }

  &.mod-main {
    flex-direction: column;
  }

  &.mod-data {
    flex-direction: column;
  }

  &.mod-actions {
    justify-content: space-between;
  }
}

.shift-rule-start-date-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px 14px 24px;
  background-color: #F8F9FA;
}

.shift-rule-start-date-box {
  display: flex;
  align-items: center;
}

.shift-rule-header {
}


.shift-rule-category-title {
  display: flex;
  margin-bottom: 16px;

  &.mod-border {
    padding-bottom: 10px;
    border-bottom: 1px solid #E7E9ED;
  }
}

.shift-rule-category-title-box {
  position: relative;
  &:first-child {
    flex: 1;
  }
}

.shift-rule-category-title-text {
  color: #373F51;
  font-size: 18px;
  line-height: 22px;
}

.shift-rule-category-title-button {
  position: relative;
  padding: 2px 8px 2px 32px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  background: #00A8FF;
  border-radius: 4px;

  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    fill: #FFFFFF;
  }

}

.shift-rule-category-title-tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 31px;
  background: #F8F9FA;
  border: 1px dashed #DFE3E9;
  border-radius: 4px;
  box-sizing: border-box;

  &:after {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    right: 38px;
    width: 15px;
    height: 15px;
    margin-top: -8.5px;
    background: #F8F9FA;
    transform: rotate(45deg);
    border: 1px dashed #DFE3E9;
  }
}

.shift-rule-category-title-tooltip-data {
  position: relative;
  width: 304px;
  padding: 10px 48px 10px 16px;
  z-index: 2;
  color: #7E8FA5;
  font-size: 13px;
  line-height: 20px;
  background: #F8F9FA;
  box-sizing: border-box;

  b {
    font-weight: 600;
  }
}

.shift-rule-category-title-tooltip-data-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  background: rgba(219, 223, 229, 0.6);
  border-radius: 50%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    fill: #7E8FA5;
  }
}

.shift-rule-category {

  &.mod-border-bottom {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E7E9ED;
  }
}

.shift-rule-header-list {
  display: flex;
  margin: 0 -18px;
  width: calc(100% + 36px);
}

.shift-rule-header-tabs {
  margin-top: 21px;
}

.shift-rule-header-box {
  padding: 0 18px;
  min-width: 0;
  box-sizing: border-box;

  &:first-child {
    flex: 1;
  }

  &.mod-flex {
    display: flex;
  }
}

.shift-rule-header-item {
  display: flex;
}

.shift-rule-header-title {
  flex: 1 100%;
  margin: 0 0 15px;
  padding: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #373F51;
}

.shift-rule-header-text {
  position: relative;
  margin: 0 24px 0 0;
  width: 170px;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 1.22;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }

  b {
    font-weight: 600;
  }

  &.mod-ico {
    padding-left: 22px;
  }

  &.mod-dark {
    color: #373F51;
  }
}

.shift-rule-header-text-location {
  width: 120px;
}

.shift-rule-header-text-ico {
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  fill: #7E8FA5;
}

.shift-rule-header-count {
  position: relative;
  display: flex;
  align-items: center;
  width: 101px;
  height: 59px;
  padding-left: 20px;
  font-weight: 600;
  line-height: normal;
  font-size: 16px;
  color: #373F51;
  background: #F8F9FA;
  border-radius: 89px;
  box-sizing: border-box;

  .service-ico {
    left: auto;
    right: 17px;
    transform: translateY(-50%);
  }
}

.shift-rule-header-count-text {
  width: 20px;
  font-size: 20px;
}

.shift-rule-header-btn {
  position: relative;
  display: block;
  padding: 0;
  width: 59px;
  height: 59px;

  &.is-active {
    .shift-rule-header-btn-ico {
      transform: translateY(-50%) translateX(-50%) rotate(180deg);
    }
  }
}

.shift-rule-header-btn-ico {
  position: absolute;
  top: 50%;
  left: 70%;
  width: 15px;
  height: 9px;
  fill: #7E8FA5;
  transform: translateY(-50%) translateX(-50%);
}

.shift-rule-header-info {
  padding: 10px 24px;
  border-top: 1px dashed #E7E9ED;
}

.shift-rule-header-info-text {
  font-size: 13px;
  color: #7E8FA5;

  &.mod-date {
    margin-left: 6px;
    font-weight: 600;
    color: #00A6FF;
  }
}

.shift-rule-data {
  border-top: 1px solid #E7E9ED;
}


.shift-rule-title {
  position: relative;
  margin-bottom: 20px;
  color: #373F51;
  font-size: 18px;
  line-height: 1.2;
  font-weight: normal;

  &.mod-ico {
    display: flex;
    align-items: center;
    padding-left: 50px;
    min-height: 40px;
  }

  &.mod-info {
    margin: 0 0 10px;
  }
}

.shift-rule-title-ico {
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  background: #F8F9FA;
  border-radius: 4px;
}

.shift-rule-box {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .item-val-error-msg {
    position: absolute;
    display: none;
    right: -22px;
    top: 50%;
    margin-top: -8px;

    .svg-ico {
      width: 16px;
      height: 16px;
      fill: #FF4743;
    }

    &:hover {
      .item-val-error-msg-text {
        display: block;
      }
    }
  }

  .item-val-error-msg-text {
    position: absolute;
    display: none;
    left: 50%;
    margin-bottom: 7px;
    bottom: 100%;
    text-align: center;
    font-size: rem(14,16);
    color: #FFFFFF;
    background: #FF4743;
    border-radius: 3px;
    transform: translateX(-50%);

    &:after {
      position: absolute;
      content: "";
      top: 100%;
      left: 50%;
      width: 10px;
      height: 10px;
      margin-top: -8px;
      background: #FF4743;
      transform: translateX(-50%) rotate(45deg);
    }

    span {
      position: relative;
      z-index: 2;
      display: block;
      background: #FF4743;
      width: 165px;
      border-radius: 3px;
    }
  }

}

.shift-rule-box-title {
  width: 212px;
  font-size: 15px;
  color: #7E8FA5;
}

.shift-rule-box-input {
  padding: 0 5px;
  font-size: 15px;
  color: #373F51;
  line-height: 22px;
  border: 1px solid #00A6FF;
  box-sizing: border-box;
  border-radius: 5px;
}

.input-holder.is-error {
  .shift-rule-box-input {
    + .form-msg {
      display: none;
    }
  }
}

.shift-rule-box-text {
  padding: 0 5px;
  font-size: 15px;
  color: #373F51;
  line-height: 24px;
  cursor: pointer;

  &.mod-blue {
    color: #00A6FF;
  }

  &.mod-gray {
    color: #AFB2B9;
  }

  &.mod-locked {
    cursor: default;
    opacity: 0.5;
  }
}

.shift-rule-box-data {
  position: relative;
  display: flex;
  align-items: center;

  &.is-error {
    .item-val-error-msg {
      display: block;
    }

    .shift-rule-box-input {
      border-color: #FF4743;
    }
  }

  .input-holder.is-accepted {
    &:after {
      margin-top: -7px;
      right: -22px;
    }
  }

  &.mod-disable-hover {
    pointer-events: none
  }
}

.shift-rule-box-btn {
  position: relative;
  width: 19px;
  height: 19px;
  margin-left: 10px;
  vertical-align: text-bottom;

  &.mod-lock {
    opacity: 0.7;
    margin-left: 5px;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 13px;
      height: 16px;
      fill: #00A8FF;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    fill: #DBDFE5;
    transform: translateY(-50%) translateX(-50%);
  }
}

.shift-rule-comments-container {
  max-width: 500px;
}

.shift-rule-actions {
  border-top: 1px solid #E7E9ED;
  background: rgba(219, 223, 229, 0.2);
}

.shift-rule-dropdown-wrappper {
  &.mod-disable-hover {
    pointer-events: none;
  }
}

.shift-rule-dropdown {
  position: absolute;
  z-index: 99;
  top: -15px;
  left: 0;
  min-width: 155px;
  background: #FFFFFF;
  border: 1px solid #DFE3E9;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-sizing: border-box;
}
.shift-rule-dropdown-list {
  display: block;
  padding: 16px 0 5px;
}
.shift-rule-dropdown-item {
  display: block;
  padding: 0 10px;
  font-size: 15px;
  margin-bottom: 10px;
  color: #333C48;
  line-height: 1.2;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #00A6FF;
  }

  &.mod-add {
    margin-bottom: 0;
    padding: 8px 0;
    text-align: center;
    border-top: 1px solid #EAECEF;
  }
}

.shift-rule-dropdown-field {
  display: flex;
  padding: 8px 10px;
  align-items: center;
}

.shift-rule-dropdown-field-title {
  font-size: 14px;
  line-height: 17px;
  color: #7E8FA5;
}

.shift-rule-dropdown-field-input {
  margin: 0 10px;
  width: 32px;
  height: 36px;
  color: #373F51;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #00A6FF;
  box-sizing: border-box;
  border-radius: 4px;
}

.shift-rule-dropdown-field-text {
  font-size: 14px;
  color: #373F51;
}

.shift-rules-msg {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.shift-rules-msg-ico {
  width: 147px;
  height: 69px;
  margin: 0 auto 48px;
}

.shift-rules-msg-text {
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #9B9B9B;
  b {
    font-weight: 600;
  }
}

.shift-rules-text-small {
  width: 271px;
  font-size: 13px;
  color: #7E8FA5;
}

.shift-rule-message {
  width: 235px;
  padding: 17px 24px 21px;
  background: #FAFBFB;
  border-radius: 4px;
  box-sizing: border-box;
}

.shift-rule-message-title {
  margin-bottom: 16px;
  color: #7E8FA5;
  font-size: 16px;
  line-height: 24px;
}

.shift-rule-message-date {
  margin-bottom: 6px;
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
}

.shift-rule-message-description {
  color: #AFB2B9;
  font-size: 14px;
  line-height: 16px;
}
