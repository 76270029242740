.list-filter-button {
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  background: linear-gradient(180deg, #FEFEFE 0%, #F6F6F6 100%);
  border: 1px solid #E8EBF0;
  box-sizing: border-box;
  border-radius: 4px;
}

.list-filter__button--text {
  width: 192px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 34px;
  text-align: left;
  background: linear-gradient(180deg, #FEFEFE 0%, #F6F6F6 100%);
  border: 1px solid #E8EBF0;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
}

.list-filter-button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 19px;
  height: 17px;
  fill: #7E8FA5;
  transform: translateX(-50%) translateY(-50%);
}

.list-filter-button__counter {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 15px;
  height: 15px;
  padding: 0 3px;
  background: #00A8FF;
  border-radius: 30px;
  color: #FDFDFD;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
  transform: translateY(-50%) translateX(50%);
}