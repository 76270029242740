.input-wrapper {
  position: relative;
  display: block;
  margin-bottom: 20px;

  input {
    display: block;
    width: 100%;
    padding: 11px 13px;
    font-size: 16px;
    color: #333C48;
    text-align: left;
    background: #FFFFFF;
    border: 1px solid #DBDFE5;
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    font-family: 'proxima_nova', Arial, Helvetica, sans-serif;

    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: #7E8FA5;
    }

    &:hover {
      border-color: #D2D6DC;

      &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
        color: #333C48;
      }
    }

    &:focus {
      color: #373F51;
      border-color: #17AFFF;

      + .input-wrapper-icon {
        fill: #17AFFF;
        border-right-color: #17AFFF;
      }
    }
  }

  .input-wrapper__label {
    display: flex;
    margin-bottom: 6px;
    font-size: 16px;
    color: #7E8FA5;
    line-height: 1.42;
    justify-content: space-between;
    font-family: 'proxima_nova_rgregular', Arial, Helvetica, sans-serif;
  }

  .input-wrapper__msg {
    display: none;
    position: relative;
    color: #333C48;
    font-size: 13px;
    line-height: 1.285714286;
    font-family: 'proxima_nova_rgregular', Arial, Helvetica, sans-serif;
  }

  b {
    display: block;
    font-size: 14px;
    font-weight: normal;
    font-family: 'proxima_nova_rgbold', Arial, Helvetica, sans-serif;
  }

  .svg-ico {
    position: absolute;
    top: 50%;
    left: 0;
    width: 19px;
    height: 19px;
    margin-top: -9px;
    fill: #F8E71C;
  }

  .tags {
    input {
      border: none;
    }
  }
}

.input-wrapper--disabled-margin {
  margin-bottom: 0;
}

.input-wrapper--with-icon {
  input {
    padding-left: 49px;
  }
}

.input-wrapper__data--error {
  input {
    border-color: #FF4743;

    &:hover {
      border-color: #FF4743;
    }

    &:focus {
      border-color: #FF4743;

      + .input-wrapper-icon {
        fill: #FF4743;
        border-right-color: #FF4743;
      }
    }
  }

  .input-wrapper-icon {
    fill: #FF4743;
    border-right-color: #FF4743;
  }

  .input-wrapper__msg {
    display: block;
    color: #FF4743;
  }
}


.input-wrapper__main-container {
  position: relative;
  display: block;
}
