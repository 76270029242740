.resources-table-item {
  display: flex;
  padding: 5px 0;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}

.resources-table-item--header {
  background: #F8FAFC;
  border-radius: 4px 4px 0 0;
}

.resources-table-item--content {
  min-height: 63px;
  border-bottom: 1px solid #E7E9ED;
  &:last-child {
    border-bottom: 0;
  }
}

.resources-table-item--edit {
  background: #F8F8F8;
}

.resources-table-item__title {
  min-width: 0;
  max-width: 100%;
  margin: 0;
  padding: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resources-table-item__icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.resources-table-item__text {
  display: block;
  min-width: 0;
  max-width: 100%;
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resources-table-item__text--name {
  padding-left: 40px;
}
