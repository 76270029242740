.live-feed-item-details-issue {
  display: flex;

  &:hover {
    .live-feed-item-details-issue__phone {
      opacity: 1;
      width: auto;
      pointer-events: auto;
    }
  }

}

.live-feed-item-details-issue__box {
  padding: 8px 0;
  box-sizing: border-box;
  flex-shrink: 0;
}

.live-feed-item-details-issue__box--img {
  position: relative;
  margin-right: 8px;
  width: 36px;
  box-sizing: border-box;
}

.live-feed-item-details-issue__box--content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: calc(100% - 58px);
  border-bottom: 1px solid #E7E9ED;
}

.live-feed-item-details-issue__person {
  position: relative;
  height: 36px;
}

.live-feed-item-details-issue__img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;

  img {
    display: inline-block;
    width: 100%;
    height: auto;
    vertical-align: top;
  }
}

.live-feed-item-details-issue__info {
  flex-grow: 1;
  width: calc(100% - 160px);
}
.live-feed-item-details-issue__contacts {
  display: flex;
}

.live-feed-item-details-issue__name {
  font-size: 0.9375rem;
  line-height: 16px;
  color: #373F51;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.live-feed-item-details-issue__phone {
  opacity: 0;
  width: 0;
  pointer-events: none;
  padding: 0 14px 0 4px;
  margin-left: 4px;
  color: #7E8FA5;
  font-size: 0.875rem;
  line-height: 16px;
  background-color: white;
  white-space: nowrap;
}

.live-feed-item-details-issue__status {
  position: relative;
  padding-left: 18px;
  font-size: 0.875rem;
  line-height: 16px;
  color: #FF4743;

}
.live-feed-item-details-issue__status-ico {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  fill: #FF4743;

}

.live-feed-item-details-issue__actions {
  position: relative;
  width: 160px;
  flex-shrink: 0;
}

.live-feed-item-details-issue__button {
  +.live-feed-item-details-issue__button {
    margin-left: 8px;
  }
}
