.profile-client-list__category {
  margin-bottom: 22px;
}

.profile-client-list__preview {
  margin: 0 -8px 4px;
}

.profile-client-header {
  margin-bottom: 22px;
  padding-bottom: 21px;
  border-bottom: 1px solid #E7E9ED;
}

.profile-client-header__list {
  display: flex;
  align-items: center;
  margin: 0 -10px;

}

.profile-client-header__box {
  padding: 0 10px;
  box-sizing: border-box;
}

.profile-client-header__box--main {
}
.profile-client-header__box--search {
  flex: 1;
}
.profile-client-header__box--dropdown {
  width: 150px;
}

.profile-client-header__title {
  margin: 0;
  padding: 0;
  color: #373F51;
  font-size: 24px;
  line-height: 29px;
}

.profile-client-header-search {
  position: relative;
  display: block;
  flex: 1;
  padding-left: 52px;
  border-left: 1px solid #E8EBF0;
}

.profile-client-header-search__input {
  display: block;
  width: 100%;
  color: #373F51;
  font-size: 14px;
  line-height: 23px;
  background: none;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  &::placeholder {
    color: #979797;
  }

  &:focus {
    border-bottom-color: #00A8FF;
  }
}

.profile-client-header-search__icon {
  position: absolute;
  top: 50%;
  left: 22px;
  width: 12px;
  height: 14px;
  fill: #B8BAC1;
  transform: translateY(-50%);
}
