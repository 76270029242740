.profile-client-category {
  display: block;
}

.profile-client-category__header {
  display: flex;
  margin-bottom: 11px;
  align-items: center;
}

.profile-client-category__box {
}

.profile-client-category__box--title {
  flex: 1;
}

.profile-client-category__title {
  margin: 0;
  padding: 0;
  color: #373F51;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}

.profile-client-category__sign {
  margin: 0;
  padding: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 20px;
}


.profile-client-category__data {
  overflow-y: auto;
  max-height: 343px;
  padding: 0 32px;
  margin: 0 -32px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126,143,165, .6);
    border-radius: 40px;
  }
}