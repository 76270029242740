.reports-hr-popup-item {
  display: inline-block;
  width: 33%;
  margin-bottom: 40px;
  padding-right: 30px;
  vertical-align: top;
  box-sizing: border-box;
}

.reports-hr-popup-group-header {
  margin-bottom: 11px;
}

.reports-hr-popup-checkbox {
  position: relative;
  padding: 0 10px 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.reports-hr-popup-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;

  &:checked {
    + .reports-hr-popup-checkbox-ico {
      border-color: #00bb28;

      .svg-ico {
        display: block;
      }
    }
  }
}

.reports-hr-popup-checkbox-ico {
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #dbdfe5;
  border-radius: 2px;
  box-sizing: border-box;
  transform: translateY(-50%);

  .svg-ico {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 9px;
    fill: #00bb28;
    transform: translateY(-50%) translateX(-50%);
  }
}

.reports-hr-popup-text {
  font-size: 14px;
  color: #373f51;
  user-select: none;

  &.mod-header {
    font-size: 15px;
    font-weight: 600;
  }
}

.reports-hr-popup-subgroup {
  padding: 10px 13px;
  border-left: 1px solid #E7E9ED;
  box-sizing: border-box;
}

.reports-hr-popup-subgroup-item {
  margin-bottom: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}