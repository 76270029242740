.reports-hr-box--employee {
  min-width: 0;
  flex: initial;
  flex-grow: 1;
  padding: 0 10px;
  text-align: left;
}
.reports-hr-box--employee-name {
  display: flex;
  width: 288px;
  align-items: center;
}
.reports-hr-box--employee-type {
  width: 176px;
}
.reports-hr-box--employee-status {
  width: 128px;
}
.reports-hr-box--employee-email {
  width: 240px;
}
.reports-hr-box--employee-role {
  width: 232px;
}
.reports-hr-box--employee-gender {
  width: 72px;
}