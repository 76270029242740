.toggle-button {
  position: relative;
  display: flex;
  align-items: center;
}

.toggle-button--margin {
  margin-bottom: 10px;
}

.toggle-button__label {
  flex: 1;
  color: #7E8FA5;
  font-size: 15px;
  line-height: 20px;
}

.toggle-button__data {
  position: relative;
  cursor: pointer;
}

.toggle-button__value {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  &:checked {
    & + .toggle-button__icon {
      background: #00A8FF;
      .toggle-button__icon-value {
        transform: translateX(12px);
      }
    }
  }
}

.toggle-button__icon {
  position: relative;
  display: block;
  width: 32px;
  height: 20px;
  background: #ABAFB6;
  border-radius: 48px;
  transition: background .3s ease;
}

.toggle-button__icon-value {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border-radius: 56px;
  transition: transform .3s ease;
}
