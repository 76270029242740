

.list-filter-tags {
  display: flex;
  padding: 16px;
  background: #F2FBFF;
  border-bottom: 1px solid #E7E9ED;
}

.list-filter-tags__box {}

.list-filter-tags__box--items {
  flex: 1;
}

.list-filter-tags__box--actions {
  white-space: nowrap;
}

.list-filter-tags__item {
  margin-right: 8px;
  margin-bottom: 8px;
}

.list-filter-tags__button {
  padding: 3px 8px;
  color: #00A8FF;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;

  &:hover {
    background: #DCF3FF;
  }

}

.list-filter-tag {
  position: relative;
  display: inline-block;
  padding: 4px 8px 3px;
  font-size: 12px;
  line-height: 15px;
  color: #373F51;
  font-weight: 600;
  background: linear-gradient(180deg, #FEFEFE 0%, #F6F6F6 100%);
  border: 1px solid #E8EBF0;
  box-sizing: border-box;
  border-radius: 4px;
  vertical-align: top;
}

.list-filter-tag--active {
  color: #fff;
  background: #00A8FF;
  border: 1px solid #00A8FF;
  .list-filter-tag__count {
    color: inherit;
  }
}

.list-filter-tag__title {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list-filter-tag__button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  width: 11px;
  height: 100%;
  cursor: pointer;
}

.list-filter-tag__ico {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  fill: #00A8FF;
  transform: translateY(-50%) translateX(-50%);
}

.list-filter-tag__count {
  display: inline-block;
  vertical-align: top;
  min-width: 23px;
  margin-left: 4px;
  color: #00A8FF;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
}


.list-filter-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  width: 240px;
  margin-top: 8px;
  background: #FFFFFF;
  border: 1px solid #E8EBF0;
  box-sizing: border-box;
  border-radius: 4px;
}

.list-filter-dropdown-padding-box {
  padding: 0 16px;
}

.list-filter-dropdown__header {
  display: flex;
  align-items: center;
  height: 32px;
}

.list-filter-dropdown__count {
  flex: 1;
  color: #7E8FA5;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}

.list-filter-dropdown__clear-all {
  padding: 0;
  margin: 0;
  color: #00A8FF;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  background: none;
  font-weight: 600;
}

.list-filter-dropdown__data {
  height: 224px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126,143,165, .6);
    border-radius: 2px;
  }
}

.list-filter-dropdown__category {
  border-bottom: 1px solid #E8EBF0;

  &:last-child {
    border-bottom: 0;
  }
}

.list-filter-dropdown__title {
  margin: 9px 0 0;
  color: #7E8FA5;
  font-size: 10px;
  line-height: 22px;
  text-transform: uppercase;
}

.list-filter-dropdown__item {
  position: relative;
  display: flex;
  min-width: 0;
  padding-right: 45px;
  align-items: center;
  height: 32px;
  color: #333C48;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    color: #00A8FF;
    background: #F8F9FA;
  }
}

.list-filter-dropdown__item--active {
  color: #00A8FF;
  background: #F8F9FA;
}

.list-filter-dropdown__deselect {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  padding: 0;
  margin: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    fill: #00A8FF;
    transform: translateY(-50%) translateX(-50%);
  }
}
