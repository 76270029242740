gccs-popups-layout {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #333C48;
  background: rgba(51, 60, 72, 0.66);
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &.popups--large {
    .popup {
      max-width: 560px;
    }
  }

  &.popups--ex-large {
    .popup {
      max-width: 824px;
    }
  }
  .popups-layout {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100%;
    height: auto !important;
    justify-content: center;
    align-items: center;
  }
  .popup {
    display: inline-block;
    width: 100%;
    max-width: 432px;
    box-sizing: border-box;
    background: #fff;
    text-align: left;
    border-radius: 4px;
  }
  .popup__header {
    position: relative;
    padding: 20px 42px 20px 24px;
    background: rgba(219,223,229, .2);
    mix-blend-mode: normal;
    border-bottom: 1px solid #E7E9ED;
  }
  .popup__title {
    padding: 0;
    margin: 0;
    font-family: 'proxima_nova', Arial, sans-serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    color: #373F51;
    letter-spacing: 0;
  }
  .popup__sign {
    margin-top: 3px;
    color: #7E8FA5;
    font-size: 15px;
    line-height: 18px;
  }
  .popup-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    padding: 0;
    z-index: 1;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      fill: #7D8FA6;
      margin: -6px 0 0 -6px;
    }
  }

  .popup-padding-box {
    padding: 20px 24px;
  }

  .popups__text {
    color: #7E8FA5;
    font-size: 16px;
    line-height: 24px;
  }

  .popup-close-btn__text {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
}
