.billing {
  .billing-estimation {
  }

  .billing-estimation__fields {
    list-style-type: none;
    padding: 0;
  }

  .billing-estimation__item {
    display: flex;
    padding: 0;
    margin: 0 0 19px;
  }

  .billing-estimation__title {
    width: 150px;
    font-size: 16px;
    color: #7E8FA5;
    line-height: 1.25;
    font-family: var(--pn-regular);
  }

  .billing-estimation__value {
    flex: 1;
  }
  .billing-estimation__value--btn {
    display: flex;
  }

  .billing-estimation__text {
    display: block;
    font-size: 16px;
    color: #333C48;
    line-height: 1.25;
    font-family: var(--pn-regular);
  }

  .billing-estimation__text_bold {
    font-family: var(--pn-bold);
  }

  .billing-estimation__text_small {
    color: #7E8FA5;
    font-size: 14px;
    line-height: 1.428571429;
  }

  .billing-estimation__link {
    font-size: 14px;
    color: #00A8FF;
    line-height: 1.428571429;
    font-family: var(--pn-regular);
    cursor: pointer;
    border-bottom: 1px solid rgba(0,166,255,.6);

    &:hover {
      border-bottom-color: transparent;
    }
  }

  .billing-estimation__value-box {
    display: block;
    padding-right: 40px;
  }

  .billing-estimation__info-wrapper {
    position: relative;
  }

  .billing-estimation__info {
    position: absolute;
    top: 180%;
    left: -15px;
    width: 352px;
    margin-top: -5px;
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.15);

    &:after {
      position: absolute;
      top: -7px;
      left: 25px;
      content: "";
      width: 15px;
      height: 15px;
      background: #FFFFFF;
      box-shadow: 0 0 9px 0 rgba(0,0,0,0.15);
      transform: rotate(45deg);
    }
  }

  .billing-estimation__info-data {
    position: relative;
    margin: 0;
    width: 100%;
    z-index: 2;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .billing-estimation__tooltip {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 12px;

    .svg-ico {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      transform: translateY(-50%) translateX(-50%);
      cursor: pointer;
    }

    .svg-ico:hover + .billing-estimation__tooltip-dropdown {
      display: block;
    }
  }

  .billing-estimation__tooltip-dropdown {
    position: absolute;
    display: none;
    left: -4px;
    top: 100%;
    margin-top: 7px;
    width: 260px;
    padding: 6px 10px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    z-index: 2;
  }

  .billing-estimation__tooltip-text {
    margin: 0;
    color: #373f51;
    font-family: var(--pn-regular);
    font-size: 13px;
    line-height: 1.31;
  }
}

