.popup-edit-payrate__data {}

.popup-edit-payrate__box {
  height: 301px;
  box-sizing: border-box;
}

.popup-edit-payrate__actions {
  height: 76px;
  box-sizing: border-box;
}

.popup-edit-payrate__item {}
.popup-edit-payrate__item--bordered {
  padding-bottom: 31px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E7E9ED;
}
.popup-edit-payrate__item--small {
  max-width: 258px;
}