.reports-hr-dropdown {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 350px;
  background: rgba(248, 249, 250, 0.5);
  border-bottom: 1px solid #E7E9ED;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: #00A8FF;
  }
}

.reports-hr-dropdown--single {
  display: block;
}

.reports-hr-dropdown-box {
  padding-top: 24px;
  padding-bottom: 24px;
  width: 59.5616024%;

  &:first-child {
    width: 40.4383976%;
    border-right: 1px solid #E7E9ED;
  }

  &:first-child:last-child {
    width: 100%;
    border-right: 0;
  }

}

.reports-hr-dropdown-header-wrapper {
  width: 100%;
  padding: 24px 24px 0;
  box-sizing: border-box;
}

.reports-hr-dropdown-title {
  margin: 0 0 24px;
  color: #373F51;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}


.reports-hr-dropdown-list {}

.reports-hr-dropdown-item {
  display: flex;
  margin-bottom: 14px;
}

.reports-hr-dropdown-item-box {
  color: #373F51;
  font-size: 15px;
  line-height: 22px;
}

.reports-hr-dropdown-item-box--key {
  width: 28px;
}

.reports-hr-dropdown-item-box--name {
  width: 255px;
}

.reports-hr-dropdown-item-box--session {
  width: 208px;
}

.reports-hr-dropdown-item-box--title {
  color: #7E8FA5;
  font-weight: 600;
}



.reports-hr-dropdown-info-list {

}

.reports-hr-dropdown-info-item {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 10px;
  }
}

.reports-hr-dropdown-info-box {
  color: #7E8FA5;
  font-size: 14px;
  line-height: 20px;
}

.reports-hr-dropdown-info-box--title {
  width: 176px;
}

.reports-hr-dropdown-info-box--text {
  width: 440px;
}

.reports-hr-dropdown-info-title {
  margin: 0;
  padding: 0;
}

.reports-hr-dropdown-info-text {
  margin: 0;
  padding: 0;
  color: #373F51;
}

.reports-hr-dropdown-info-tag {
  display: inline;
  color: #373F51;
  margin-right: 5px;
  &:after {
    content: ',';
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}
.reports-hr-dropdown-info-tag--highlighted {
  font-weight: 600;
}