.live-feed-item-details {
  width: 100%;
}

.live-feed-item-details__content {
  display: flex;
  box-sizing: border-box;
}

.live-feed-item-details__box {
  width: 50%;
  padding: 8px 16px;
  box-sizing: border-box;
  &:first-child {
    border-right: 1px solid #E7E9ED;
  }
}

.live-feed-item-details__actions {
  padding: 10px 16px;
  background: #F9F8F6;
  text-align: right;
  border-radius: 0 0 4px 4px;
}

.live-feed-item-details__btn {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.live-feed-item-details__item {
  &:not(:last-child) {
    margin-bottom: 19px;
  }

}
.live-feed-item-details__title {
  display: inline-block;
  margin: 0 4px 8px 0;
  color: #7E8FA5;
  font-size: 0.875rem;
  line-height: 18px;
}

.live-feed-item-details__counter {
  display: inline-block;
  padding: 0 4px;
  min-width: 24px;
  color: #7E8FA5;
  font-weight: 600;
  line-height: 16px;
  font-size: 0.625rem;
  text-align: center;
  border: 1px solid #7E8FA5;
  border-radius: 40px;
  box-sizing: border-box;
}

.live-feed-item-details__counter--red {
  color: #FF4743;
  border-color: #FF4743;
}

