.popup-edit-payrate-clients__data {}

.popup-edit-payrate-clients__box {
  height: 271px;
  box-sizing: border-box;
}

.popup-edit-payrate-clients__actions {
  box-sizing: border-box;
}

.popup-edit-payrate-clients__item {
  position: relative;
  margin-bottom: 25px;
}
.popup-edit-payrate-clients__item--bordered {
  padding-bottom: 31px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E7E9ED;
}
.popup-edit-payrate-clients__item--small {
  max-width: 240px;
}

.popup-edit-payrate-clients__hint {
  top: auto;
  bottom: 0;
  margin-left: 25px;
}