.list-filter-tooltip {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 50%;
  width: 227px;
  margin-top: 20px;
  border: 1px dashed #DFE3E9;
  border-radius: 4px;
  background: #F8F9FA;
  box-sizing: border-box;
  transform: translateX(-50%);

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 28px;
    height: 28px;
    margin-top: -6px;
    margin-left: -14px;
    transform: rotate(45deg);
    border-width: 1px;
    border-style: dashed;
    border-bottom: 0;
    border-right: 0;
    border-color: #DFE3E9;
    background: #F8F9FA;
    box-sizing: border-box;
  }
}

.list-filter-tooltip__data {
  position: relative;
  padding: 20px 46px 20px 16px;
  z-index: 2;
  background: #F8F9FA;
}

.list-filter-tooltip__text {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  color: #7E8FA5;
}

.list-filter-tooltip__button {
  position: absolute;
  top: 23px;
  right: 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  background: rgba(219, 223, 229, 0.6);
  border-radius: 50%;
}

.list-filter-tooltip__ico {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  fill: #7E8FA5;
}
