.resources-table-wrapper {
  display: block;
  margin-bottom: 20px;
}

.resources-table {
  width: 100%;
  margin-bottom: 23px;
  background: #FFFFFF;
  border: 1px solid #DBDFE5;
  box-sizing: border-box;
  border-radius: 4px;
}