.availability-report-header {
  display: flex;
  min-height: 64px;
  border-top: 1px solid #E7E9ED;
  border-bottom: 1px solid #E7E9ED;
  background-color: #F8F8F8;
}

.availability-report-list {
  min-height: 64px;
  margin-bottom: 30px;
}

.availability-report-box {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  padding: 10px 16px;
  border-right: 1px solid #E7E9ED;
  box-sizing: border-box;
  justify-content: flex-end;

  &:last-child {
    border-right: none;
  }

  &.mod-align-right {
    justify-content: flex-end;
    text-align: right;
  }

  &.mod-sub-main {
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.mod-sub {
    flex: 1;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.mod-holidays {
    height: 100%;
    flex: 1;
  }
}

.availability-report-sub-box-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  border-right: 1px solid #E7E9ED;
  box-sizing: border-box;

  &.mod-sub {
    flex-direction: row;
  }
}

.availability-report-sub-box-item {
  display: flex;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid #E7E9ED;


  &:last-child {
    border-bottom: none;
  }
}

.availability-report-box--name {
  width: 235px;
  justify-content: flex-start;
}

.availability-report-box--status {
  width: 131px;
  justify-content: flex-start;
}

.availability-report-box--type {
  width: 143px;
  justify-content: flex-start;
}

.availability-report-box--holiday {
  width: 433px;
}

.availability-report-box--day-off {
  width: 143px;
}

.availability-report-box--sick-day {
  width: 143px;
}

.availability-report-box--blowout {
  width: 143px;
}

.availability-report-box--list-name {
  display: flex;
  width: 351px;
  align-items: center;
}

.availability-report-title {
  position: relative;
  margin: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.availability-report-sub-title {
  margin: 0;
  color: #373F51;
  font-size: 13px;
  line-height: 16px;
}

.availability-report-item {
  display: flex;
  min-height: 64px;
  border-bottom: 1px solid #E7E9ED;
}

.availability-report-text {
  margin: 0;
  color: #373F51;
  font-size: 15px;
  line-height: 1.42;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.availability-report-text-secondary {
  color: #7E8FA5;
}