.history-graph-wrapper {
  .history-graph {
    margin-bottom: 8px;
  }
}

.history-graph {
  position: relative;
  margin-bottom: 76px;
}

.history-graph-data {
  display: flex;
}

.history-graph-box {
  position: relative;
  flex: 1;
  height: 96px;
  border-bottom: 1px solid #B7C1CD;

  &:last-child {
    flex: 0;
    .history-graph-line-wrapper {
      display: none;
    }
  }
}

.history-graph-text {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 8px 0 0 0;
  padding-top: 4px;
  color: #373F51;
  font-size: 10px;
  transform: translateX(-50%);

  &:after {
    position: absolute;
    content: '';
    left: 50%;
    width: 1px;
    bottom: 100%;
    height: 104px;
    background: #B7C1CD;
    transform: translateX(-50%);
  }
}

.history-graph-line-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  transform: translateX(-50%);
}

.history-graph-line {
  position: relative;
  flex: 1;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    margin-bottom: -7px;
    background: #E7E9ED;
    transform: translateX(-50%);
  }

}

.history-graph-line-text {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  width: 1px;
  height: 100%;
  background: #373F51;
  transform: translateX(-50%);

  &:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 100%;
    height: 1px;
    width: 5px;
    background: #373F51;
    transform: translateX(-50%);
  }
}

.history-graph-line-text-val {
  position: absolute;
  left: 50%;
  bottom: 100%;
  font-size: 12px;
  line-height: 22px;
  color: #373F51;
  white-space: nowrap;
  transform: translateX(-50%);
}
.history-graph-timeline {
  position: absolute;
  display: flex;
  left: -1px;
  bottom: 24px;
  width: 100%;
  height: 36px;
}

.history-graph-timeline-item {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
  height: 100%;
  color: #b2ebbe;
  font-size: 12px;
  line-height: 15px;
  background: #00BB28;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    color: #fff;
    .history-graph-timeline-dropdown {
      display: grid;
    }
    .history-graph-timeline-icon {
      fill: #fff;
    }
    .history-graph-timeline-count {
      fill: #fff;
      background: none;
    }
  }
}
.history-graph-timeline-item-sep {
  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background: #99E4A9;
  }
}

.history-graph-timeline-item--no-data {
  background: #FF5956;
  .history-graph-timeline-dropdown {
    width: 100px;
  }
}

.history-graph-timeline-text-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.history-graph-timeline-text {
  display: block;
  width: 100%;
  min-width: 0;
  padding: 0 16px 0 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
}

.history-graph-timeline-icon {
  position: absolute;
  top: 50%;
  left: 9px;
  width: 14px;
  height: 13px;
  fill: #b2ebbe;
  margin-top: -7px;
}

.history-graph-timeline-count {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 8px;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: #00BB28;
  font-size: 9px;
  line-height: 17px;
  font-weight: 600;
  fill: #b2ebbe;
  background: none;
  border-radius: 2px;
  text-align: center;

  svg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: inherit;
  }
}

.history-graph-timeline-dropdown {
  position: absolute;
  display: none;
  padding: 9px;
  left: 50%;
  bottom: 100%;
  width: 160px;
  margin-bottom: 16px;
  background: #373f51;
  border-radius: 2px;
  transform: translateX(-50%);

  &:after {
    position: absolute;
    content: '';
    top: 100%;
    left: 50%;
    width: 15px;
    height: 15px;
    margin-top: -5px;
    background: #373f51;
    transform: rotate(45deg) translateX(-50%);
  }
}

.history-graph-timeline-dropdown-icon {
  margin-bottom: 11px;
  width: 14px;
  height: 14px;
  fill: #7E8FA5;
}


.history-graph-timeline-dropdown-item {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #FFFFFF;

  &:last-child {
    margin-bottom: 0;
  }
}

.history-graph-timeline-dropdown-name {
  max-width: 250px;
}

.history-graph-timeline-dropdown-name--highlight {
  color: #FF5956;
}

.history-graph-timeline-dropdown-date {
  color: #7e8fa5;
  letter-spacing: 0.02em;
  font-weight: 400;
  white-space: nowrap;
}
