.live-feed-item-preview__actions {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.live-feed-item-preview__select {
  display: none;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.live-feed-item-preview__select-field {
  position: absolute;
  width: 0;
  height: 0;
  clip: rect(0,0,0,0);

  &:checked ~ .live-feed-item-preview__select-checkbox {
    background-color: #00A8FF;
    border: 1px solid #00A8FF;

    .live-feed-item-preview__checkbox-ico {
      display: block;
    }
  }
}

.live-feed-item-preview__select-checkbox {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #A4A6AE;
  transition: .3s ease;
}

.live-feed-item-preview__checkbox-ico {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 10px;
  height: 8px;
  fill: #ffffff;
}

.live-feed-item-preview__dropdown {
}

.live-feed-item-preview__dropdown--disabled {
  pointer-events: none;
}

