.live-feed-item-preview__label {
  display: none;
  position: absolute;
  top: -4px;
  left: -8px;
  font-size: 0.625rem;
  line-height: 20px;
  height: 20px;
  padding: 0 8px;
  color: #FDFDFD;
  background: #00A8FF;
  box-shadow: 0 4px 4px rgba(0, 166, 255, 0.08);
  border-radius: 80px;
  text-transform: uppercase;
  font-weight: 600;
  box-sizing: border-box;
}

.live-feed-item-preview__label--disabled {
  background: #A4A6AE;
  box-shadow: 0 4px 4px rgba(164, 166, 174, 0.08);
}
