.profile-client-preview {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 8px 50px 8px 64px;
  font-size: 18px;
  line-height: 24px;
  color: #373F51;
  font-weight: 600;
  border-radius: 4px;
  box-sizing: border-box;
}

.profile-client-preview--active {
  background: rgba(219, 223, 229, 0.2);
}

.profile-client-preview__data {
  min-width: 0;
}
.profile-client-preview__img {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 40px;
  height: 40px;
  background: #1EC882;
  border-radius: 4px;
}

.profile-client-preview__img-val {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: contain;
}

.profile-client-preview__img-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  color: #ffffff;
  text-align: center;
  font-size: 0.625rem;
  line-height: 12px;
  transform: translateY(-50%) translate(-50%);
}

.profile-client-preview__name {
  display: block;
  flex-grow: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile-client-preview__button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 100%;
}
.profile-client-preview__button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  fill: #7E8FA5;
  transform: translateX(-50%) translateY(-50%);
}