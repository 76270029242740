.clock-in__list {
  display: flex;
  margin: 0 -8px;
}

.clock-in__wrapper {
  width: 50%;
  padding: 0 8px;
  box-sizing: border-box;
}

.clock-in__item {
  min-height: 100%;
}