.live-feed-item-preview__title {
  position: relative;
  margin: 0;
  color: #7E8FA5;
  font-size: 0.9375rem;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.live-feed-item-preview__subtitle {
  position: relative;
  margin: 0;
  color: #A4A6AE;
  font-size: 0.875rem;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
