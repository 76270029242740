.licence-sub-table-wrapper {
  min-height: 168px;
  max-height: 360px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-right: 0;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-right: 0;
    border-radius: 2px;
  }
}

.licence-sub-table-box {
  flex-grow: 1;
  padding-right: 15px;
  box-sizing: border-box;

  &.mod-right-align {
    padding-right: 0;
    padding-left: 15px;
    text-align: right;
  }
}


.licence-sub-table-box--name {
  display: flex;
  width: 408px;
  align-items: center;
}

.licence-sub-table-box--type {
  width: 168px;
}

.licence-sub-table-box--number {
  width: 188px;
}

.licence-sub-table-box--status {
  width: 135px;
}

.licence-sub-table-box--issue-date {
  width: 110px;
}

.licence-sub-table-box--expire-date {
  width: 140px;
}

.licence-sub-table-box--file {
  display: flex;
  justify-content: center;
  width: 130px;
}
