.live-feed-header {
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.live-feed-header__stats {
  display: flex;
  flex-grow: 1;
  margin: 0 -6px;
}

.live-feed-header__box {
  position: relative;
  padding: 0 6px;
  display: flex;
  align-items: center;

  + .live-feed-header__box {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #A4A6AE;
    }
  }
}

.live-feed-header__box--total {
  + .live-feed-header__box {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1px;
      width: 1px;
      height: 100%;
      background-color: #E7E9ED;
    }
  }
}

.live-feed-header__title {
  margin-right: 2px;
  font-size: 0.75rem;
  line-height: 1.6;
  color: #A4A6AE;
  text-transform: uppercase;

}

.live-feed-header__value {
  font-size: 0.75rem;
  color: #373F51;
  font-weight: 600;
}

.live-feed-header__filters {
  margin: 0 -13px;
  display: flex;

}

.live-feed-header__filter-item {
  position: relative;
  display: flex;
  padding: 0 13px;

  + .live-feed-header__filter-item {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1px;
      width: 1px;
      height: 100%;
      background-color: #E7E9ED;
    }
  }
}
