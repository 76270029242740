.billing {
  .billing-users {
    display: flex;
    margin: 0 -20px;
  }

  .billing-users__item {
    display: flex;
    max-width: 50%;
    min-width: 0;
    padding: 0 20px;
    align-items: center;
  }

  .billing-user {
    position: relative;
    display: block;
    padding-left: 55px;
    max-width: 100%;
    padding-right: 15px;
    box-sizing: border-box;
  }
  .billing-user__avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    transform: translateZ(0);
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
  .billing-user__name {
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #333C48;
    line-height: 1.25;
    font-family: var(--pn-regular);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .billing-user__email {
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #7E8FA5;
    font-family: var(--pn-regular);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .billing-recipients {
    display: block;
    max-width: calc(100% -  35px);
    padding-right: 15px;
    box-sizing: border-box;
  }

  .billing-recipients__title {
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #7E8FA5;
    font-family: var(--pn-regular);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .billing-recipients__emails {
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #373F51;
    font-family: var(--pn-regular);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
