.resources-table-item__actions {
  display: flex;
  justify-content: flex-end;
  margin: 0 -4px;
}

.resources-table-item__actions-box {
  padding: 0 4px;
}

.resources-table-item-button {
  position: relative;
  width: 36px;
  height: 36px;
  background: #1BB934;
  border-radius: 4px;
  border: 1px solid #1BB934;
  box-sizing: border-box;
  &:hover {
    background: #00A412;
    border-color: #1BB934;
  }
}

.resources-table-item-button--decline {
  background: linear-gradient(180deg, #FEFEFE 0%, #F6F6F6 100%);
  border-color: #E8EBF0;

  &:hover {
    border-color: #E8EBF0;
    background-image: linear-gradient(-180deg, #FAFAFA 0%, #F0F0F0 100%);
  }
}

.resources-table-item-button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  fill: #7E8FA5;
  transform: translateY(-50%) translateX(-50%);
}

.resources-table-item-button__icon--checked {
  width: 16px;
  height: 12px;
  fill: #FFFFFF;
}