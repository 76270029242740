.reports-hr-box {
  flex: 1;
  text-align: right;
}

.reports-hr-box--name {
  display: flex;
  width: 400px;
  align-items: center;
  text-align: left;
}

.reports-hr-box--employee-status {
  width: 158px;

  .person-status {
    margin-right: 0;
  }
}

.reports-hr-box--data {
  width: 137px;
}

.reports-hr-box--history {
  width: 284px;
}

.reports-hr-box--address {
  width: 208px;
}

.reports-hr-box--licence-name {
  display: flex;
  flex: 2;
  width: 558px;
  align-items: center;
  text-align: left;
}

.reports-hr-box--licence-presence {
  width: 284px;
}

.reports-hr-box--licence-absence {
  width: 284px;
}