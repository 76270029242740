.resources-table-payrate {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.resources-table-payrate__box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2px 0;
}

.resources-table-payrate__val {
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
}

.resources-table-payrate__sign {
  color: #1BB934;
  font-size: 16px;
  line-height: 19px;
  margin-left: 4px;
}

.resources-table-payrate__sign--negative {
  color: #FF4743;
}

.resources-table-payrate__button {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding: 0;
  fill: rgba(126, 143, 165, 0.5);
  &:hover {
    fill: rgba(126, 143, 165, 1);
  }
}

.resources-table-payrate__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: inherit;
}