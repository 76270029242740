.live-feed {
  position: relative;
}

.live-feed__section--list {
  height:calc(100vh - 192px);
  padding-bottom: 10px;
  box-sizing: border-box;
}

.live-feed__list {
  height: 100%;
}

.live-feed__fix {
  padding-right: 65px;
}
