.list-filter {
  position: relative;
  display: inline-block;
}

.list-filter--with-title {
  display: flex;

  .list-filter-button {
    border-left: 0;
    border-radius: 0 4px 4px 0;
  }

  .list-filter__dropdown {
    top: auto;
    left: 246px;
    bottom: 0;
    right: auto;
  }
}