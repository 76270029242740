.resources-table-item__box {
  padding: 0 16px;
  min-width: 0;
  flex-grow: 1;
  box-sizing: border-box;
}

.resources-table-item__box--name {
  position: relative;
  width: 256px;
}

.resources-table-item__box--roles {
  width: 196px;
}

.resources-table-item__box--type {
  width: 140px;
}

.resources-table-item__box--hours {
  width: 120px;
  padding-left: 8px;
  text-align: right;
}

.resources-table-item__box--rate {
  width: 104px;
  padding-left: 8px;
  text-align: right;
}

.resources-table-item__box--contractor-pay-rate {
  width: 192px;
  padding-left: 8px;
  text-align: right;
}

.resources-table-item__box--employee-pay-rate {
  width: 192px;
  padding-left: 8px;
  text-align: right;
  padding-right: 25px;
}

.resources-table-item__box--actions {
  width: 115px;
  padding-left: 0;
  text-align: right;
}

.resources-table-item__box--hours {
  .resources-table-item-input-group {
    margin-right: -11px;
  }
}
.resources-table-item__box--rate,
.resources-table-item__box--contractor-pay-rate,
.resources-table-item__box--employee-pay-rate {
  .resources-table-item-input {
    margin-right: -11px;
  }
}