.shift-rule-days {
  display: flex;
  margin: 0 0 15px;
  padding: 0;
  list-style-type: none;
  &.mod-no-margin {
    margin-bottom: 0;
  }
}

.shift-rule-days-item {
  margin: 0 1px;
  width: 24px;
  height: 24px;
  color: #7E8FA5;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  background: #FEFEFE;
  border: 1px solid #DBDFE5;
  box-sizing: border-box;
  border-radius: 3px;

  &.is-active {
    color: #373F51;
    background: #DBDFE5;
  }

  &.mod-btn {
    color: #7E8FA5;
    background: linear-gradient(180deg, #FEFEFE 0%, #F6F6F6 100%);
    border: 1px solid #DBDFE5;
    box-sizing: border-box;

    &.is-active {
      color: #00A6FF;
      background: #F1FBFF;
      border: 1px solid #00A6FF;
      box-sizing: border-box;
      box-shadow: 0 2px 6px rgba(55, 63, 81, 0.1);
      border-radius: 3px;
      cursor: default;
    }

  }
}