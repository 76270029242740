.create-service-category {
  margin-bottom: 35px;

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}
.create-service-category--small {
  max-width: 480px;
}
.create-service-category-title {
  display: block;
  margin: 0 0 18px;
  color: #373F51;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.create-service-category-list {
  margin: 0 -12px;
}

.create-service-category-list--flex {
  display: flex;

  .create-service-category-item {
    &:first-child {
      flex: 1;
    }

    &:last-child {
      width: 188px;
    }
  }
}

.create-service-category-item {
  display: block;
  padding: 0 12px;
}