.clock-in-progress {
  display: flex;
  align-items: center;

  .clock-in-progress__error {
    position: relative;
    flex: 1;
    text-align: center;
  }

  .clock-in-progress__line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #FF4743;
  }

  .clock-in-progress__text {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #FF4743;
    background-color: #FFFFFF;
    padding: 0 4px;
  }

  .progress-bar {
    position: relative;
    flex: 1;

    &:hover {
      .progress-bar__time-wrapper {
        display: flex;
      }
    }
  }

  .progress-bar--border {
    .progress-bar__holder {
      background-color: #F8F9FA;
      border: 1px solid #E7E9ED;
    }
  }

  .progress-bar--green {
    .progress-bar__holder {
      background-color: rgba(0, 187, 40, 0.16);
    }

    .progress-bar__value {
      background-color: #00BB28;
    }
  }

  .progress-bar--red {
    .progress-bar__holder {
      background-color: rgba(255, 71, 67, 0.16);
    }

    .progress-bar__value {
      background-color: #FF4743;
    }
  }

  .progress-bar--finished {
    .progress-bar__value {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    .progress-bar__tick {
      display: none;
    }
  }

  .progress-bar__holder {
    height: 8px;
    background-color: #EAEDF1;
    box-sizing: border-box;
    border-radius: 16px;
  }

  .progress-bar__value {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    width: 0;
    max-width: 100%;
    background-color: #7E8FA5;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .progress-bar__tick {
    position: absolute;
    top: -2px;
    right: 0;
    width: 1px;
    height: calc(100% + 4px);
    background-color: inherit;
  }

  .progress-bar__time-wrapper {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    margin-top: 3px;
    width: 100%;
    justify-content: space-between;
  }

  .progress-bar__time {
    font-size: 10px;
    line-height: 12px;
    color: #A4A6AE;
  }

  .progress-bar__time--gray {
    color: #7E8FA5;
  }
}

.clock-in-progress--agenda-item {
  margin-top: 13px;
}

.clock-in-progress__button-wrapper {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.clock-in-progress__button {
  position: relative;
  width: 16px;
  height: 16px;
  vertical-align: top;

  &:hover {
    .clock-in-progress__button-ico {
      fill: #7E8FA5;
    }
  }
}

.clock-in-progress__button--blue {
  &:hover {
    .clock-in-progress__button-ico {
      fill: #00A8FF;
    }
  }

  .clock-in-progress__button-ico {
    fill: #00A8FF;
  }
}

.clock-in-progress__button--red {
  &:hover {
    .clock-in-progress__button-ico {
      fill: #FF4743;
    }
  }

  .clock-in-progress__button-ico {
    fill: #FF4743;
  }
}

.clock-in-progress__button-ico {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%);
  fill: #A4A6AE;
  transition: fill .15s ease
}
