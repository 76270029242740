.billing {
  .billing-history {
    padding: 40px 0 25px;
  }
  .billing-history__item {
    display: flex;
    text-align: left;
    border-bottom: 1px solid rgba(193,201,212,.5);
  }

  .billing-history__box {
    padding: 15px;
    flex-grow: 1;
    box-sizing: border-box;
    &:first-child {
      padding-left: 0;
    }
  }

  .billing-history__box--date {
    width: 120px;
  }

  .billing-history__box--count {
    width: 165px;
  }

  .billing-history__box--type {
    width: 135px;
  }

  .billing-history__box--cost {
    width: 125px;
  }

  .billing-history__box--actions {
    min-width: 52px;
  }

  .billing-history__text {
    margin: 0;
    font-size: 14px;
    color: #373F51;
    line-height: 1.428571429;
    font-family:  var(--pn-regular);
  }

  .billing-history__text--bold {
    font-family:  var(--pn-bold);
  }

  .billing-history__btn {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: top;
  }

  .billing-history__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    fill: #7E8FA5;
    margin: -7px 0 0 -7px;
  }
}
