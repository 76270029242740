.input-wrapper-checkbox {

}

.form-checkbox-checkbox__data {
  position: relative;
  padding-left: 24px;
}

.input-wrapper-checkbox__data {
  position: relative;
}

.input-wrapper-checkbox__value {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked {
    & + .form-checkbox-checkbox__data {
      .form-checkbox-checkbox__icon {
        background: #1BB934;
        border-color: #1BB934;
      }

      .form-checkbox-checkbox__icon-val {
        display: block;
      }
    }
  }
  &[disabled] {
    & + .form-checkbox-checkbox__data {
      opacity: 0.38;
    }
  }
}

.form-checkbox-checkbox__icon {
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1.5px solid #B2BCC9;
  box-sizing: border-box;
  border-radius: 1px;
}

.form-checkbox-checkbox__icon-val {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 10px;
  fill: #fff;
  transform: translateY(-50%) translateX(-50%);
}

.form-checkbox-checkbox__label {
  color: #7E8FA5;
  font-size: 15px;
  line-height: 20px;
  user-select: none;
}