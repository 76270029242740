.client-services-header {
  margin-bottom: 22px;
  padding-bottom: 21px;
  border-bottom: 1px solid #E7E9ED;
}

.client-services-header__list {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}

.client-services-header__box {
  padding: 0 10px;
  box-sizing: border-box;
}

.client-services-header__box--main {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: flex-end;
}

.client-services-header__box--dropdown {
  width: 160px;
}

.client-services-header__title {
  margin: 0 4px 0 0;
  padding: 0;
  color: #373F51;
  font-size: 24px;
  line-height: 29px;
}

.client-services-header__sign {
  margin: 0;
  padding: 0;
  flex: 1;
  min-width: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.client-services__category {
  margin-bottom: 46px;
}