.billing {
  display: flex;
  margin: 0 -15px;

  .billing__wrapper {
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .billing__box {
    padding: 30px 30px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E7E9ED;
  }

  .billing__title {
    margin: 0 0 25px;
    padding: 0;
    font-size: 18px;
    color: #333C48;
    line-height: 1.25;
    font-family: var(--pn-regular);
  }

  .billing-btn {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    border: 1px solid #E8EBF0;
    background: #7E8FA5 linear-gradient(-180deg, #FFFFFF 0%, #F6F6F6 100%);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .billing-btn__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    fill: #7E8FA5;
    width: 13px;
    height: 13px;
    margin: -7px 0 0 -7px;
  }

  .billing-link {
    padding: 0;
    font-size: 14px;
    color: #00A8FF;
    line-height: 1.428571429;
    font-family: var(--pn-regular);
    border-bottom: 1px solid rgba(0,166,255,.6);

    &:hover {
      border-bottom-color: transparent;
    }
  }
}
