.popup-locations {
  .autocomplete-add-btn {
    position: relative;
    padding: 3px 0;
    text-align: center;

    &:after {
      position: absolute;
      content: "";
      top: -7px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #EAECEF;
    }
  }
}

.popup-locations__dropdown {
  .dropdown-current {
    width: 236px;
  }
}

.popup-locations__list {
  padding-left: 19px;
  border-left: 1px solid #00A8FF;
}

.popup-locations__item {
  position: relative;
  display: inline-block;
}

.popup-locations__description {
  margin: 0 0 11px;
  color: #373F51;
  font-size: 14px;
  line-height: 20px;
}

.popup-locations__text {
  position: relative;
  margin: 0;
  padding-left: 20px;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 22px;
}

.popup-locations__icon {
  position: absolute;
  top: 5px;
  left: 0;
  width: 12px;
  height: 9px;
  fill: #7E8FA5;
}

.popup-locations__hint {

}
