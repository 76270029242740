.reports-hr-dropdown-header {
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 22px;
  align-items: center;
}

.reports-hr-dropdown-header-box {
  display: flex;
  align-items: center;
}

.reports-hr-dropdown-header-box--title {
  flex: 1;
}

.reports-hr-dropdown-header-title {
  margin: 0;
  padding: 0;
  color: #373F51;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}

.reports-hr-dropdown-header-sep {
  margin: 0 24px;
  width: 1px;
  height: 36px;
  background: #E8EBF0;
}

.reports-hr-dropdown-header-item {

}