.live-feed-item-preview__lock {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 15px;
  height: 18px;
  fill: #A4A6AE;
}
