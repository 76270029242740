.live-feed-header__date-range {
  margin-right: 8px;
  .input-holder {
    display: inline-block;
    float: left;
  }

  .form-input {
    font-size: 0.875rem;
    padding: 4px 10px 3px 32px;
    border: 1px solid #E8EBF0;
    color: #373F51;
    cursor: pointer;
  }

  .form-input-ico {
    width: 28px;
    border: 1px solid #E8EBF0;
    &:after {
      content: none;
    }
  }

  .svg-ico {
    width: 14px;
    height: 14px;
    fill: #7E8FA5;
  }

  .datepicker-box {
    display: table-cell;
  }

  .datepicker-menu {
    display: table-cell;
    margin-left: 0;
    min-width: 200px;
  }

  .datepicker-menu-item {
    padding: 10px 5px;
  }

  .datepicker-menu-settings {
    margin-top: 40px;
  }

}






