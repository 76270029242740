.live-feed-list {
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #E7E9ED;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
  width: 4px;
  border-radius: 3px;
}

  &::-webkit-scrollbar-track {
    background: #fff;
    border-right: 0;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-right: 0;
    border-radius: 2px;
  }
}

.live-feed-list__section {
  padding: 0 24px;
  user-select: none;
  box-sizing: border-box;
}

.live-feed-list__section--empty {
  margin-bottom: 0;
  height: calc(100vh - 226px);
}

.life-feed-list__box {
  padding: 16px 0 20px 0;
  box-sizing: border-box;
}

.live-feed-list__title {
  position: sticky;
  top: 0;
  margin: 0;
  padding: 11px 24px;
  background: #FFFFFF;
  border-bottom: 1px solid #E7E9ED;
  color: #373F51;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 600;
  z-index: 3;
  box-sizing: border-box;
}

.live-feed-list__title--urgent {
  color: #FF4743;
}

.live-feed-list__title-accent {
  font-weight: normal;
  font-style: italic;
}
