.client-services-item {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E7E9ED;
  box-sizing: border-box;
}

.client-services-item--header {
  padding-bottom: 8px;
}

.client-services-item--content {
  padding: 8px 0;
  min-height: 63px;
}

.client-services-item__box {
  padding: 0 20px;
  min-width: 0;
}

.client-services-item__box--resource {
  width: 376px;
  padding-left: 0;
}

.client-services-item__box--pay-rate {
  width: 216px;
  text-align: right;
}

.client-services-item__box--actions {
  width: 172px;
  padding: 0;
}

.client-services-item__title {
  color: #7E8FA5;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.client-services-item__icon {
  left: 10px;
}

.client-services-item__text {
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
}

.client-services-item__text--icon {
  padding-left: 56px;
}

.client-services-item__button {
  position: relative;
  width: 100%;
  height: 25px;
  color: #FDFDFE;
  font-size: 12px;
  line-height: 21px;
  font-weight: 600;
  border-radius: 3px;
  background: #1BB934;
  border: 1px solid #1BB934;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background: #00A412;
    border-color: #1BB934;
  }
}

.client-services-item__button--unapprove {
  color: #7E8FA5;
  background: #FEFEFE;
  background-image: linear-gradient(180deg, #FEFEFE 0%, #F6F6F6 100%);
  border-color: #E8EBF0;

  &:hover {
    border-color: #E8EBF0;
    background: #FEFEFE;
    background-image: linear-gradient(-180deg, #FAFAFA 0%, #F0F0F0 100%);
  }
}

.client-services-category__title {
  margin-bottom: 16px;
  color: #373F51;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}
