.list-filter__dropdown {
  position: absolute;
  z-index: 99;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: #fff;
  border: 1px solid #E7E9ED;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(55, 63, 81, 0.1);
}

.list-filter__list {
  position: relative;
  display: flex;
}

.list-filter__box {
  height: 312px;
}

.list-filter__box--categories {
  width: 241px;
  margin-right: -1px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    position: absolute;
    width: 1px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: none;
    border-right: 0;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-right: 0;
    border-radius: 2px;
  }
}

.list-filter__box--options {
  width: 280px;
  background: rgba(219, 223, 229, 0.2);
  border-left: 1px solid #E7E9ED;
}