.live-feed-item {
  position: relative;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #E7E9ED;
  box-shadow: 0 4px 8px rgba(55, 63, 81, 0.04);

  &:hover {
    border: 1px solid #E7E9ED;
    .live-feed-item__box--preview {
      background: #F4FCFF;
    }
  }
}

.live-feed-item__box--preview {
  position: relative;
  padding: 8px 0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 16px;
    height: 1px;
    width: calc(100% - 32px);
    background-color: #E7E9ED;
  }
}

.live-feed-item__box--details {
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}

.live-feed-item__wrapper {
  display: block;
  margin-top: -100%;
  transition: .5s ease-out;
}
