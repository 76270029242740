.client-services-item-pay-rate {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.client-services-item-pay-rate__box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2px 0;
}

.client-services-item-pay-rate__val {
  color: #373F51;
  font-size: 16px;
  line-height: 19px;
}

.client-services-item-pay-rate__button {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding: 0;
  fill: rgba(126, 143, 165, 0.5);
  &:hover {
    fill: rgba(126, 143, 165, 1);
  }
}

.client-services-item-pay-rate__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: inherit;
}

.client-services-item__box .mod-currency {
  width: 77px;
}

.client-services-item__box .mod-currency .form-input {
  padding-left: 45px
}
