.live-feed-item-preview {
  display: flex;
  &:hover {
    .live-feed-item-preview__select {
      display: block;
    }
  }
}

.live-feed-item-preview__box {
  padding: 0 16px;
  box-sizing: border-box;
  flex-shrink: 1;
  min-width: 0;
}

.live-feed-item-preview__box--status {
  flex-basis: 190px;
  display: flex;
  align-items: center;
}

.live-feed-item-preview__box--title {
  flex-basis: 296px;
}

.live-feed-item-preview__box--place {
  flex-basis: 244px;
  padding-right: 0;
}

.live-feed-item-preview__box--time {
  flex-basis: 256px;
  text-align: right;
  padding-right: 0;
}

.live-feed-item-preview__box--actions {
  flex-basis: 86px;
}
