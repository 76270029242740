.clock-in-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E7E9ED;
  box-sizing: border-box;
  border-radius: 4px;
}

.clock-in-preview__header {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  background: #F8F9FA;
  border-bottom: 1px solid #F8F9FA;
  border-radius: 4px 4px 0 0;
}

.clock-in-preview__title {
  flex: 1;
  margin: 0;
  padding: 0 8px 0 0;
  color: #373F51;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.clock-in-preview__box {
  display: flex;
  padding-top: 56px;
  padding-bottom: 47px;
  flex-direction: column;
  flex: 1;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.clock-in-preview__box--disabled {
  .clock-in-preview__img-value {
    filter: grayscale(100%);
    opacity: .25;
  }
}

.clock-in-preview__padding-box {
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}

.clock-in-preview__img {
  position: relative;
  width: 168px;
  height: 168px;
  margin: 0 auto;
}

.clock-in-preview__img-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.clock-in-preview__text {
  position: relative;
  display: block;
  margin-top: 40px;
  padding-top: 32px;
  text-align: center;
  color: #7E8FA5;
  font-size: 16px;
  line-height: 24px;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 16px;
    height: 1px;
    background: #DBDFE5;
    transform: translateX(-50%);
  }
}