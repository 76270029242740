.billing {
  .billing-card {
    margin-bottom: 20px;
  }

  .billing-card__list {
    display: flex;
    align-items: center;
  }

  .billing-card__box {}

  .billing-card__box--ico {
    width: 40px;
  }

  .billing-card__box--number {
    padding-right: 60px;
  }

  .billing-card__box--payment-system {}

  .billing-card__number {
    font-size: 16px;
    color: #7E8FA5;
    letter-spacing: 1px;
    font-family: var(--pn-regular);
  }

  .billing-card__ico {
    display: inline-block;
    vertical-align: top;
  }

  .billing-card__ico--mastercard {
    width: 29px;
    height: 22px;
  }

  .billing-card__ico--payment-system {
    width: 104px;
    height: 22px;
  }

}
