.popup-invite-employee__sep {
  position: relative;
  display: block;
  margin-top: 32px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #7E8FA5;
  margin-bottom: 11px;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #E7E9ED;
  }
}

.popup-invite-employee__text {
  position: relative;
  z-index: 2;
  padding: 0 26px;
  background: #fff;
}

input.popup-invite-employee__input {
  padding-right: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  &:focus + .popup-invite-employee__button {
    border-color: #17AFFF;
  }
}

.btn.popup-invite-employee__button {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  line-height: 44px;
}