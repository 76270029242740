.resource-list {
  display: inline-block;
  list-style-type: none;
  vertical-align: top;
  margin: 0;
  padding: 0;
  border: 0;
}

.resource-list__item {
  position: relative;
  width: 38px;
  height: 38px;
  margin: 0 -6px 0 0;
  float: left;

  &.mod-num {
    margin-top: 1px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    box-shadow: 0 0 0 2px #00a8ff;
    font-size: 1rem;
    color: #00A8FF;
    text-align: center;
    background: #fff;
    font-weight: bold;
    border-radius: 50%;
  }
}

.resource-list__item--red {
  .resource-list__box {
    border: 1px solid #FF4743
  }
}

.resource-list__item--text {
  .resource-list__box {
    background: #7E8FA5
  }
}

.resource-list__box {
  display: block;
  position: relative;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-sizing: border-box;
  background-color: #fff;

  &:hover {
    .resource-list__name {
      display: block;
    }
  }
}

.resource-list__name {
  left: -18px;
}

.resource-list__img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;

  img {
    display: inline-block;
    width: 100%;
    height: auto;
    vertical-align: top;
  }
}

.resource-list__service-ico {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
