.list-filter-category__data {
  position: relative;
  display: block;
  padding: 14px 48px 15px 20px;
  font-size: 15px;
  line-height: 18px;
  color: #7E8FA5;
  background: #fff;
  font-weight: 600;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #E7E9ED;

  &:last-child {
    border-bottom: 0;
  }
}

.list-filter-category--active .list-filter-category__data {
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  color: #373F51;
  background: #F8F9FA;
  cursor: default;

  .list-filter-category__icon-main {
    fill: #00A8FF;
  }
}

.list-filter-category--disabled .list-filter-category__data {
  color: #B3B6BD;
  cursor: not-allowed;
}

.list-filter-category__title {
  margin: 0;
  padding: 0;
}

.list-filter-category__icon-wrapper {
  position: absolute;
  top: 50%;
  right: 18px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}

.list-filter-category__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.list-filter-category__icon-main {
  width: 8px;
  height: 12px;
  fill: #B8BAC1;
}

.list-filter-category__icon-disabled {
  width: 13px;
  height: 16px;
  fill: #DDE1E7;

  &:hover {
    cursor: pointer;
    & + .list-filter-category__tooltip {
      display: block;
    }
  }
}

.list-filter-category__tooltip {
  position: absolute;
  top: 50%;
  right: 100%;
  display: none;
  margin-right: 8px;
  padding: 6px 8px;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 15px;
  transform: translateY(-50%);
  background: #373F51;
  border-radius: 2px;
  text-align: center;
}