.reports-hr-header {
  display: flex;
  padding: 24px;
  align-items: center;
}

.reports-hr-header-box {
  display: flex;
  align-items: center;

  &.mod-full-container {
    flex: 1;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.reports-hr-header-item {
  margin: 0 8px;
}

.reports-hr-header-title {
  margin: 0;
  color: #373F51;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;

  &.mod-sub-list {
    font-size: 15px;
    line-height: 18px;
  }
}

.reports-hr-header-text {
  font-size: 14px;
  color: #7E8FA5;
}

.reports-hr-header-sep {
  width: 1px;
  height: 30px;
  margin: 0 14px;
  background: #E7E9ED;
}

.reports-hr-header-filter-btn {
  padding-right: 48px;
}

.reports-hr-header-filter-ico {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #DBDFE5;

  .svg-ico {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 19px;
    height: 17px;
    fill: #7E8FA5;
    transform: translateX(-50%) translateY(-50%);
  }
}
