.popup-new-manager__dropdown {
  .dropdown-current {
    width: 236px;
  }
}

.popup-new-manager__list {
  padding-left: 19px;
  border-left: 1px solid #00A8FF;
}

.popup-new-manager__item {
  margin-bottom: 11px;
}

.popup-new-manager__description {
  margin: 0 0 11px;
  color: #373F51;
  font-size: 14px;
  line-height: 20px;
}

.popup-new-manager__text {
  position: relative;
  margin: 0;
  padding-left: 20px;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 22px;
}

.popup-new-manager__icon {
  position: absolute;
  top: 5px;
  left: 0;
  width: 12px;
  height: 9px;
  fill: #7E8FA5;
}

