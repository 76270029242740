@keyframes feedItemMoveIn {
  from {
    display: none;
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    display: block;
    opacity: 1;
    transform: translateX(0);

  }
}


@keyframes feedItemMoveOut {
  from {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
  to {
    display: none;
    opacity: 0;
    transform: translateX(50%);
    width: 0;
  }
}

@keyframes feedItemMoveUp {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
    margin: 0;
    border: none;
  }
}

@keyframes feedItemMoveDown {
  from {
    max-height: 0;
  }
  to {
    max-height: 100px;
  }
}

