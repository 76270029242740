.reports-hr {
  padding: 24px;
}

.reports-hr-item {}
.reports-hr-item--active {
  .reports-hr-item-data {
    background: #F1FBFF;
    &:hover {
      background: #F1FBFF;
    }
  }
  .reports-hr-dropdown-icon {
    fill: #00A8FF;
    transform: rotate(90deg);
  }
}

.reports-hr-item-padding-box {
  padding-right: 24px;
  padding-left: 24px;
}

.reports-hr-item-header {
  display: flex;
  width: 100%;
  padding-bottom: 9px;
  border-bottom: 1px solid #E7E9ED;
  box-sizing: border-box;
}

.reports-hr-item-data {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 64px;
  align-items: center;
  font-size: 15px;
  border-bottom: 1px solid #E7E9ED;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: #F8F9FA;

    .reports-hr-dropdown-icon {
      fill: #00A8FF;
    }
  }

  &.mod-sub-list {
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 56px;
    background: transparent;
    cursor: default;
  }
}

.reports-hr-title {
  position: relative;
  display: inline-block;
  margin: 0;
  color: #7E8FA5;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  vertical-align: middle;
}

.reports-hr-text {
  position: relative;
  margin: 0;
  color: #333C48;
  font-size: 16px;
  line-height: 1.42;
}

.reports-hr-text--no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.reports-hr-title-sortable {
  padding: 0 12px 0 0;
  cursor: pointer;
  .reports-hr-title-ico {
    display: block;
  }
}

.reports-hr-title-sortable-top {
  color: #4d5262;
  .reports-hr-title-ico {
    fill: #4d5262;
    transform: translateY(-50%) rotate(-90deg);
  }
}

.reports-hr-title-sortable-bottom {
  color: #4d5262;
  .reports-hr-title-ico {
    fill: #4d5262;
  }
}

.reports-hr-title-ico {
  position: absolute;
  display: none;
  right: 0;
  top: 50%;
  width: 4px;
  height: 8px;
  transform: translateY(-50%) rotate(90deg);
  fill: #7E8FA5;
}

.reports-hr-dropdown-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 4px;
  height: 8px;
  margin-top: -4px;
  fill: #7E8FA5;
}
.reports-hr-img-wrapper {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  margin-right: 15px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;

  &.mod-client {
    border-radius: 4px;
  }
}

.reports-hr-img-wrapper--licence {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  margin-left: 2px;
}

.reports-hr-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.reports-hr-text {
  margin: 0;
  padding: 0;

  &.mod-red {
    color: #FF4743;
  }
}

.reports-hr-status {
  display: inline-flex;
  padding: 3px 8px 2px;
  font-size: 12px;
  font-weight: 600;
  color: #7E8FA5;
  border: 1px solid #7E8FA5;
  border-radius: 11px;
  text-transform: uppercase;

  &.mod-passed {
    color: #00BB28;
    border: 1px solid #00BB28;
  }

  &.mod-expired {
    color: #FF4743;
    border: 1px solid #FF4743;
  }
}

.reports-hr-file-type {
  position: relative;
  float: left;
  width: 23px;
  height: 30px;
  cursor: pointer;

  .svg-ico {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translateY(-50%) translateX(-50%);
  }
}
